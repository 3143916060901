import React from 'react'
import Partnership3 from './Partnership3'
import Partnership2 from './Partnership2'
import Partnership from './Partnership'
import Gdev from '../components/GDev/Gdev';
import Mediap from './Media/Mediap';

function CompanyPartners() {
  return (
    <div style={{background:'white'}}>
      <Partnership/>
      <Partnership2/>
      <Partnership3/>
      <Gdev/>
      <Mediap/>
    </div>
  )
}

export default CompanyPartners
