import React, { useState, useRef } from 'react';
import axios from 'axios'; 
import './Contact.css';
import emailjs from '@emailjs/browser';
import contact1 from '../bg/contact1.png';

function Form() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
        .sendForm('service_q1h2gjg', 'template_s9bs8d9', form.current, 'MgRhKcHX2PJjf3_gx')
        .then(
            (result) => {
                console.log('Email Sent Successfully!', result);
                alert('Email sent successfully!');
            },
            (error) => {
                console.error('FAILED...', error);
                alert('Failed to send email. Please try again later.');
            },
        );
    };


    return (
      
      <div className="form-container">
          <div className="form-content">
              <h2 className='form-header-text'>CONTACT FORM</h2>
              <form ref ={form} onSubmit ={sendEmail}>
                  <input type="name" name="name" placeholder="Full Name" required />
                  <input type="email" name="email" placeholder="Email" required />
                  <input type="contact_no" name="contact_no" placeholder="Contact Number" required />
                  <textarea placeholder="Message" rows="15" cols="30" id="message" name="message" class="textarea"></textarea>
                  <button type="submit">SUBMIT</button>
              </form>
          </div>
        </div>
  )
}

export default Form
