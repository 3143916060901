// import { Container} from '@mui/material'
import React from 'react'
import Carousel from '../Carousel'

function ImageList() {
  return (
          
            <Carousel/>

          )
        }

export default ImageList
