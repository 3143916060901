import React, { useState, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faFacebook, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './Contact.css';
import emailjs from '@emailjs/browser';
import contact1 from '../bg/contact1.png';
import Form from '../Contact/Form';

export default function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
        .sendForm('service_q1h2gjg', 'template_s9bs8d9', form.current, 'MgRhKcHX2PJjf3_gx')
        .then(
            (result) => {
                console.log('Email Sent Successfully!', result);
                alert('Email sent successfully!');
            },
            (error) => {
                console.error('FAILED...', error);
                alert('Failed to send email. Please try again later.');
            },
        );
    };


    return (
        <div>
        <div id="contact" className="main-cont">
            <div className="text-cont">
                <h1 className="cont-header">CONTACT US</h1>
                <p className="cont-desc">
                    Would you like to talk to us and discuss the possibility of us working together?
                    Please fill out the contact form and give us more details and information about your inquiry.
                    Our team will make sure to get back to you as soon as we can.
                </p>

                <div className="socials">
                    <div className="social-icons">
                        <a href="https://www.tiktok.com/@darkleaguestudios?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTiktok} style={{ fontSize: '40px' }} />
                        </a>
                        <a href="https://www.facebook.com/darkIeaguestudios" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} style={{ fontSize: '40px' }} />
                        </a>
                        <a href="https://www.instagram.com/darkleague_studios?utm_source=ig_web_button_share_sheet&igsh=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '40px' }} />
                        </a>
                        <a href="https://www.youtube.com/@darkleaguestudios" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faYoutube} style={{ fontSize: '40px' }} />
                        </a>
                        <a href="https://www.linkedin.com/company/darkleaguestudios/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: '40px' }} />
                        </a>
                    </div>
                </div>
            </div> 
      {/* <div className ="img_formcontainer">
      <div className="card-container">   
        <img src={contact1} alt="Contact-Picture" className="cont-image"/>     
      </div>
      <div className="form-container">
          <div className="form-content">
              <h2 className='form-header-text'>CONTACT FORM</h2>
              <form ref ={form} onSubmit ={sendEmail}>
                  <input type="name" name="name" placeholder="Full Name" required />
                  <input type="email" name="email" placeholder="Email" required />
                  <input type="contact_no" name="contact_no" placeholder="Contact Number" required />
                  <textarea placeholder="Message" rows="15" cols="30" id="message" name="message" class="textarea"></textarea>
                  <button type="submit">SUBMIT</button>
              </form>
          </div>
        </div>
      </div> */}
      
        </div>
        <Form/>
        </div>
    );
}
