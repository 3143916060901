import React from 'react'
import './Media.css';

function Mediap() {
  return (
    <div>
      <div style={{background:'white', padding:'5%'}}>
      <div className="grid-contaiiner">
      <h1 className='br5'>
          <span style={{fontFamily:'bai-light'}}>MEDIA</span> PARTNERS
        </h1>
        <div className="grid-rrow">
          <div className="grid-ittem"><img className='Media1' src='\Media\ABS-CBN_News_Logo_2014.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='Media2' src='\Media\GMA News TV Logo.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='Media3' src='\Media\Philstar Global logo.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='Media4' src='\Media\manila-bulletin_hd_logo.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='Media5' src='\Media\SpinPH logo (1).png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='Media6' src='\Media\Tierbreaker Times logo.png' alt="PBA" /></div>
        </div>
        <div className="grid-rrow2">
          <div className="grid-ittem"><img className='Media7' src='\Media\Rappler_logo.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='Media8' src='\Img\Icons\one sports.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='Media9' src='\Media\PNA-PHILIPPINES-NEWS-AGENCY-LOGO.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='Media0' src='\Media\Business Mirror logo.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='Media11' src='\Media\Business World logo.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='Media12' src='\Media\Inquirer dot Net logo.png' alt="PBA" /></div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Mediap
