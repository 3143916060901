import React from 'react'
import './Estudyante.css';
import CAST from '../CAST';
import { Box } from '@mui/material';

function Estudyante() {
  return (
    <div id='ee'>
        <div className='ee-div'>
          <img src="\Img\ee (2).png" alt="Uclash Logo" className="ee-img" />
            <p className='ee-desc'>
              <span className='highlight'>Estudyante Esports</span> is one of the flagship pillars of <span className='highlight'>Dark League Studios</span>. 
              The program aims to increase awareness, appreciation and actual practice of esports, as well as, develop academic partnerships in order to 
              support the development of the fast-growing esports community within schools and campuses nationwide.
            </p>
            <h1 className='cast-header'>
            C.A.S.T.  Programs
            </h1>
            <div>
            <CAST/>
            </div>
            <h1 className='ee-header'>
              Campus Affiliations
            </h1>
            <Box className='ee-box'>
              <p className='ee-desc2'>
                <span className='highlight2'>A door to endless opportunities</span> Schools who affiliate with Estudyante Esports as a school chapter will open a door of endless opportunities for students, 
                providing a structured framework where students can engage in competitive gaming, 
                while also promoting academic excellence and personal growth.
              </p>
            </Box>
            <Box className='ee-box2'>
                  <img src="\Img\Assets\eecover.png" alt="Uclash Logo" className="ee-img4" />
            </Box>
            <img src="\Img\Assets\EE (1).png" alt="Uclash Logo" className="ee-img3" />
            
            <div className='ee-div2'>
              <img src="\Img\Assets\center pic.png" alt="Uclash Logo" className="ee-img2" />
                  <h2 className='ee-header2'>
                    Leveling Up Academia Through the Estudyante Esports SUMMEET
                  </h2>
                  <p className='ee-desc3'>
                  A groundbreaking event that celebrates the fusion of esports and education. As esports continues to rise in prominence, this event highlights 
                  the invaluable connection between gaming and academic institutions by proving that esports is now an 
                  integral part in the learning process and career path of students and school administrators especially here in the Philippines.
                  </p>
            </div>
        </div>
    </div>
  )
}

export default Estudyante
