import React from 'react'
import './Gdev.css';

function Gdev() {
  return (
    <div style={{background:'white', padding:'5%'}}>
      <div className="grid-contaiiner">
      <h1 className='br4'>
          <span style={{fontFamily:'bai-light'}}>GAME</span> DEVELOPERS
        </h1>
        <div className="grid-rrow">
          <div className="grid-ittem"><img className='paar' src='\Img\Icons\Riot Games Logo.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='paar' src='\bbrands\level infinite.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='moon-paar' src='\Img\Assets\brands\6c61173b4a6787d1637ca49ad8187a3f.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='paar' src='\bbrands\playpark.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='paar' src='\bbrands\hoyoverse.png' alt="PBA" /></div>
          <div className="grid-ittem"><img className='paar' src='\Img\Assets\brands\garena.png' alt="PBA" /></div>
        </div>
      </div>
    </div>
  )
}

export default Gdev
