import React from 'react'
import './Core.css'

function Core() {
  return (
        <div className='core-div'>
            <h1 className='core-header'><span className='compf'>Company</span> Founders
        </h1>
        <div className="core-container">
            <div className='divdiv'>
                <div className="core-box"><img src="\Cores\boss bobby.jpg" alt="Uclash Logo" className="core1" /></div><div className='div-pos'><h1 className='firstl'><span >BOBBY</span> Y. ROSALES</h1><p className='pos'>CO-FOUNDER AND CHAIRMAN OF THE BOARD</p></div>
            </div>
            <div className='divdiv'>
                <div className="core-box"><img src="\Cores\sir alfrancis.jpg" alt="Uclash Logo" className="core2" /></div><div className='div-pos'><h1 className='firstl'><span >ALFRANCIS</span> P. CHUA</h1><p className='pos'>CO-FOUNDER AND CHIEF MARKETING OFFICER</p></div>
            </div>
            <div className='divdiv'>
                <div className="core-box"><img src="\Cores\sir ac.jpg" alt="Uclash Logo" className="core3" /></div><div className='div-pos'><h1 className='firstl'><span >A.C.</span> D. VALDENOR</h1><p className='pos'>CO-FOUNDER AND CHIEF EXECUTIVE OFFICER</p></div>
            </div>
            <div className='divdiv'>
                <div className="core-box"><img src="\Cores\ms cherry.jpg" alt="Uclash Logo" className="core4" /></div ><div className='div-pos'><h1 className='firstl'><span >CHERRY</span> U. VALDENOR</h1><p className='pos'>CO-FOUNDER AND CHIEF REVENUE OFFICER</p></div>
            </div>
        </div>
        </div>
  )
}

export default Core
