import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import './Loading.css';

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} hove-arrow`}
      style={{ ...style, display: 'block', right: '10px' }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} hove-arrow`}
      style={{ ...style, display: 'block', left: '10px' }}
      onClick={onClick}
    />
  );
};

function Hove() {
  const settings = {
    autoplay: false,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    loop: true,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
          loop: true,
        }
      }
    ]
  };

  return (
    <div className='voh'>
      <h1 className='history'>
        <span style={{fontFamily:'bai-light'}}>POST</span> EVENTS
      </h1>
    <Container className='hove-cont'>
      <Slider {...settings}>
      <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\Img\Assets\uaap.png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>UAAP ESPORTS</span>
            <p className="card-content">
              8 Universities competing for the trophy! Come and Witness the first ever inaugural UAAP Esports tournament this coming August 13 to 21! Who will dominate and conquer this season?
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">AUGUST</span>
            <span className="date">2024</span>
          </div>
        </div>
        
        <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\Img\Assets\telus.png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>TELUS INTERNATIONAL</span>
            <p className="card-content">
            TELUS International Philippines team members are about to flex their skills and teamwork in an epic MLBB face-off. Powered by Dark League Studios!
            Thrilled to join forces again with #TELUSPH for the TIP MLBB Intersite Tournament
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">AUGUST</span>
            <span className="date">2024</span>
          </div>
        </div>

      <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\Img\Assets\suc.png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>SMART UNIVERSITY CLASH</span>
            <p className="card-content">
            From contenders to legends, the journey of our heroes at the Smart University Clash: Rise of Heroes is a tale of perseverance and triumph. .
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">JUNE</span>
            <span className="date">2024</span>
          </div>
        </div>

        <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\events\fist fight 2.png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>FIST FIGHT 2</span>
            <p className="card-content">
              Dark League Studios flagship grassroots tournament for TEKKEN, the most 
              interactive onsite gathering after the pandemic, with positive praise from the community for its second season.
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">MARCH</span>
            <span className="date">2024</span>
          </div>
        </div>

        <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\events\summeet (3).png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>SUMMEET</span>
            <p className="card-content">
              A groundbreaking event that celebrates the fusion of esports and education. 
              As esports continues to rise in prominence, this event highlights the invaluable 
              connection between gaming and academic institutions.
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">MARCH</span>
            <span className="date">2024</span>
          </div>
        </div>

        <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\events\jbl.png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>JBL QUANTUM</span>
            <p className="card-content">
             The 2nd leg of the JBL Quantum Campus Mania for Mobile Legends: Bang Bang and the first-ever on-campus 
             esports tournament in the history of universities; in partnership with Dark League Studios, JBL Philippines
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">FEBRUARY</span>
            <span className="date">2023</span>
          </div>
        </div>

        <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\events\gamerfest (2).png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>GAMER FEST</span>
            <p className="card-content">
            Aims to revolutionize the gaming experience of SMART Giga Arena users and 
            gaming enthusiasts through engaging activities and exciting rewards, 
            and promoting inclusivity in the esports community.
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">OCTOBER</span>
            <span className="date">2023</span>
          </div>
        </div>

        <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\events\street ballers (2).png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>STREET BALLERS</span>
            <p className="card-content">
              Supporting the core of the PBA community in an intense 3x3 mobile basketball tournament, 
              PBA Esports Bakbakan STREETBALLERS launched a celebration of exciting grassroot tournaments.
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">OCTOBER</span>
            <span className="date">2023</span>
          </div>
        </div>

        <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\events\delta-league.png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>DELTA LEAGUE</span>
            <p className="card-content">
              Dark League Studios' biggest grassroots esports initiative to support the Call of Duty: 
              Mobile scene in the Philippines garnering over 650,000 views and engagements for its first run.
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">JUNE</span>
            <span className="date">2023</span>
          </div>
        </div>

        <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\events\rise of agents (1).png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>RISE OF AGENTS</span>
            <p className="card-content">
              Get ready to prove your aim! Unleash your powers and ignite the Radiant flame. Can you out-frag, outplay, 
              and outsmart your opponents to claim the throne? Agents, assemble! 
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">JUNE</span>
            <span className="date">2023</span>
          </div>
        </div>

        <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\events\queens-league.png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>QUEENS LEAGUE</span>
            <p className="card-content">
              To further create an inclusive representation for gamers across the spectrum, QUEENS LEAGUE 
              by Dark League Studios was made to pave a way for female MLBB teams to show their strength and 
              skills in MLBB.
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">MARCH</span>
            <span className="date">2023</span>
          </div>
        </div>

        <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\events\kings-league-1920.png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>KINGS LEAGUE</span>
            <p className="card-content">
              The first and the biggest onground grassroots to amateur esports tournament for Mobile Legends: Bang Bang, 
              with over 128 Teams who participated, and 3,000 people who attended.
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">SEPTEMBER</span>
            <span className="date">2022</span>
          </div>
        </div>
      </Slider>
    </Container>
    </div>
  );
}

export default Hove;
