import React from 'react'
import './Pbae.css';
import { Box } from '@mui/material';

function Pbae() {
  return (
    <div id='pbae'>
          <div className='pba-div'>
            <img src="\Img\PBA (1).png" alt="Uclash Logo" className="pba-img" />
              <p className='pba-desc'>
                <span className='pba-highlight'>PBA Esports Bakbakan</span> is an esports league in the Philippines organized by the 
                <span className='pba-highlight'> Philippine Basketball Association (PBA)</span> in partnership with Dark League Studios. 
                The league consists of the esports squads of the PBA's twelve franchise teams composed of combined PBA players and esports standout talents.
              </p>

              <div className='pba-div4'>                
                  <img src="\Img\pba\who are we 2.png" alt="Uclash Logo" className="pba-img10" />
                  <img src="\Img\pba\who are we 3.png" alt="Uclash Logo" className="pba-img10" />
                  <img src="\Img\pba\who are we.png" alt="Uclash Logo" className="pba-img10" />
            </div>    
              <h1 className='pba-header'>
                <span style={{fontFamily:'bai-light'}}>PBA</span> Tournaments
              </h1>
            <Box className='pba-box2'>
            <div style={{width: '100%', height: '100%', position: 'relative'}}>                
                <img src="\PBA\pba.svg" alt="Uclash Logo" className="pba-img2" />            
            </div>
            </Box>
            <Box className='pba-box3'>
            <div style={{width: '10%', height: '100%', position: 'relative'}}>                
                <img src="\PBA\pba3.svg" alt="Uclash Logo" className="pba-img4" />            
            </div>
            </Box>
            <div className='pba-div3'>
                <div class="caarrd">
                  <img src="\Img\pba\Dota_logo.svg.png" alt="Uclash Logo" className="pba-img8" />
                </div>
                <div class="caarrd">
                  <img src="\Img\pba\codm-logo.png" alt="Uclash Logo" className="pba-img8" />
                </div>                
            </div>
            <div className='pba-div2'>
                <div class="caarrd">
                  <img src="\Img\pba\MLBB NEXT logo.png" alt="Uclash Logo" className="pba-img8" />
                </div>
                <div class="caarrd">
                  <img src="\Img\pba\PUBG (2).png" alt="Uclash Logo" className="pba-img9" />
                </div>                
            </div>            
          </div>
    </div>
  )
}

export default Pbae
