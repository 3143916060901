import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from 'react-bootstrap';
import './Scarousel.css';

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} scarousel-arrow`}
      style={{ ...style, display: 'block', right: '10px' }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} scarousel-arrow`}
      style={{ ...style, display: 'block', left: '10px' }}
      onClick={onClick}
    />
  );
};

const Scarousel = () => {
  const settings = {
    dots: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    loop: true,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [
    '/SmartCarousel/Screenshot 2024-06-25 133643.png',
    '/SmartCarousel/Screenshot 2024-06-25 133700.png',
    '/SmartCarousel/Screenshot 2024-06-25 133734.png',
    '/SmartCarousel/Screenshot 2024-06-25 133748.png',
  ];

  return (
    <div className="scarousel-container">
      <Container>
        <Slider {...settings}>
          {images.map((imageUrl, index) => (
            <div key={index} className="scarousel-slide">
              <img
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                style={{ objectFit: 'cover', width: '100%', height: 'auto', marginTop:'2%' }}
              />
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default Scarousel;
