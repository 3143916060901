import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import './CAST.css';

export default function ActionAreaCard() {
  return (
    <div className='div-cast'>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ maxWidth: 350, backgroundColor: 'transparent' }}>
              <CardMedia
                component="img"
                height="auto"
                image="\Cast\caravan (1).png"
                alt="caravans"
              />
              <CardContent className= 'car-con1'>
                <Typography gutterBottom variant="h5" component="div" sx={{ color: 'black', fontFamily: 'bai-bold'}}>
                  CARAVANS
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ color: 'black' }}>
                  Bringing esports activities and learning events to schools.  
                </Typography>
              </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ maxWidth: 350, backgroundColor: 'white' }}>
              <CardMedia
                component="img"
                height="auto"
                image="\Cast\academe.png"
                alt="academe"
              />
              <CardContent className= 'car-con2'>
                <Typography gutterBottom variant="h5" component="div" sx={{ color: 'black', fontFamily: 'bai-bold'}}>
                  ACADEME
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ color: 'black' }}>
                  Campus accreditation and forging linkages, partnering with schools in esports promotion and education
                </Typography>
              </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ maxWidth: 350, backgroundColor: 'white' }}>
              <CardMedia
                component="img"
                height="auto"
                image="\Cast\summeet (2).png"
                alt="summeet"
              />
              <CardContent className= 'car-con3'>
                <Typography gutterBottom variant="h5" component="div" sx={{ color: 'black', fontFamily: 'bai-bold'}}>
                  SUMM<span style={{color:'darkviolet'}}>EE</span>T
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ color: 'black' }}>
                    Big events focusing on esports industry developments, best practices sharing, esports learning and digital gaming as a whole
                </Typography>
              </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ maxWidth: 350, backgroundColor: 'white' }}>
              <CardMedia
                component="img"
                height="auto"
                image="\Cast\tournament.png"
                alt="tournaments"
              />
              <CardContent className= 'car-con4'>
                <Typography gutterBottom variant="h5" component="div" sx={{ color: 'black', fontFamily: 'bai-bold'}}>
                  TOURNAMENTS
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ color: 'black' }}>
                  Organizing the most exciting student and campus esports leagues across different esports titles. 
                    <p style={{marginTop:"-1%", fontWeight:'bold'}}>(Mobile, PC, Console)</p>
                </Typography>
              </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
