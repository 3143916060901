import React from 'react'
import './Partnership3.css';

function Partnership3() {
  return (
    <div>
      <div className="grid-cooontainer">
        <div className="grid-rooow">
        <div className="grid-iteeem"><img className='parrr' src='\Img\Partners_Brands\Meralco-logo.png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='fantech-parrr' src='\Img\Assets\brands\Fantech Logo White (1).png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='razer-parrr' src='\Img\Assets\brands\razergoldlogo-freelogovectors.net_.png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='nparrr' src='\Img\Brands\NYFD Fries-logo.jpg' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='gparrr' src='\Img\Brands\GSM-Blue-logo.png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='parrr' src='\bbrands\Gongcha (1).png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='sparrr' src='\Img\Brands\PP-Streeballers-Logo-SB.png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='rog-parrr' src='\bbrands\rog (1).png' alt="PBA" /></div>          
        </div>
        <div className="grid-rooow">
        <div className="grid-iteeem"><img className='parrr' src='\Img\Assets\brands\chick n juicy.png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='parrr' src='\Img\Brands\Pizza-Hut-Gaming.png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='con-parrr' src='\bbrands\converge.png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='ari-parrr' src='\Img\Brands\normal_THE_ARISTOCRAT_RESTAURANT-logo.png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='taters-parrr' src='\Img\Brands\taters-logo.png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='parrr' src='\Img\Brands\PARES-MNL-LOGO.png' alt="PBA" /></div>
          <div className="grid-iteeem"><img className='rob-parrr' src='\Img\Partners_Brands\Robinsons_Malls-logo.png' alt="PBA" /></div>
        </div>
      </div>
    </div>
  )
}

export default Partnership3
