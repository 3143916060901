import React, { useState } from 'react';
import './Header3.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="menu-toggle" onClick={handleToggle}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <li className = "home-class"><a href="#home">HOME</a></li>
        <li className = "who-we-are-class"><a href="/home#who_we_are">WHO<span style={{color:'black'}}>l</span>WE<span style={{color:'black'}}>l</span>ARE</a></li>
        <li className="dropdown">
          <a href="#smart">
          <button className="dropbtn">PROGRAMS</button>
          </a>
          <div className="dropdown-content">
            <a href="#smart">SMART GIGA ARENA</a>
            <a href="#ee">ESTUDYANTE ESPORTS</a>
            <a href="#pbae">PBA ESPORTS</a>
          </div>
        </li>
        <div className="navbar-logo">
          <li>
            <a href="#home">
              <img className='logo' src="/Img/Company_Logo.png" alt="Company Logo" />
            </a>
          </li>
        </div>
        <li><a href="/home#company_events">EVENTS</a></li>
        <li><a href="/home#services">SERVICES</a></li>
        <li><a href="/home#contact">CONTACT<span style={{color:'black'}}>l</span>US</a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
