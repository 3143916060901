import React from 'react';
import './Partnership.css';

class Grid extends React.Component {
  render() {
    return (
      <div className='div-mai'>
        <h3 className='partnerss'>
            <span style={{fontFamily:'bai-light'}}>COMPANY</span> PARTNERSHIPS
        </h3>
        <h1 className='br'>
          OUR <span className='long-term'>PARTNERS</span> 
        </h1>
        <div className="grid-row">
          <div className="grid-item"><img className='par' src='\bbrands\smart (1).png' alt="PBA" /></div>
          <div className="grid-item"><img className='pba-par' src='\bbrands\PBA.png' alt="PBA" /></div>
          <div className="grid-item"><img className='par' src='\bbrands\peso (1).png' alt="PBA" /></div>
          <div className="grid-item"><img className='par' src='\bbrands\sanmig.png' alt="PBA" /></div>
          <div className="grid-item"><img className='partt' src='\Img\Partners_Brands\startdust.png' alt="PBA" /></div>
        </div>
        <div className="grid-row2">
          <div className="grid-item"><img className='par3' src='\Img\Icons\Cignal.svg.png' alt="PBA" /></div>
          <div className="grid-item"><img className='pba-pare' src='\Img\Icons\UAAP_UP-11.png' alt="PBA" /></div>
          <div className="grid-item"><img className='par2' src='\Img\Icons\one sports.png' alt="PBA" /></div>          
        </div>
      </div>
    );
  }
}

export default Grid;