import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home/home';
import Smart from './components/SGA/Smart';
import Pbae from './components/PBA/Pbae';
import Estudyante from './components/Estudyante/Estudyante';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route path='/home' element={<Home/>}/>
          <Route index element={<Home />}/>
          <Route path= '/ee' element = {<Estudyante/>}/>
          <Route path= '/pbae' element = {<Pbae/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
