import React, { useState, useEffect, useRef } from 'react';
import './Count.css';

const AnotherCounts = ({ finalCount, duration }) => {
  const [count, setCount] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        // When component enters the viewport, start counting
        const timeInterval = 10; // Interval in milliseconds
        const totalIncrements = Math.ceil(duration / timeInterval);
        const increment = Math.ceil(finalCount / totalIncrements);

        const interval = setInterval(() => {
          setCount(prevCount => {
            if (prevCount + increment >= finalCount) {
              clearInterval(interval);
              return finalCount;
            } else {
              return prevCount + increment;
            }
          });
        }, timeInterval);

        // Disconnect the observer once the component is visible
        observer.disconnect();
      }
    });

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [finalCount, duration]);

  return (
    <span ref={ref} className="animatedCounter">
      {count.toLocaleString()}
    </span>
  );
};

export default AnotherCounts;
