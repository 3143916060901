import React from 'react'
import './Smart.css';
import { Box } from '@mui/material';
import Scarousel from '../Smart Carousel/Scarousel';

function Smart() {
  return (
    <div id='smart'>
        <div>
          <div className='smart-div'>
            <img src="\Img\smart.png" alt="Uclash Logo" className="smart-img" />
              <p className='smart-desc'>
                <span className='highlight'>SMART Giga Arena</span> is an online arcade gaming and esports tournament platform. With Dark League Studios, 
                this platform brings diverse and rewarding gaming experiences for gamers of all sorts through daily events, arcade games with leaderboards, and esports tournament circuits.
              </p>
              <Box className='smart-box'>
                <Scarousel/>
              </Box>
              <h1 className='smart-header2'>
              <span style={{fontFamily:'bai-light'}}>SGA </span>Tournaments and Programs
              </h1>
              {/* <p className='smart-desc2'>
                Dark League Studios competitive Tournaments that are being offered in the SMART Giga Arena Platform. 
                Offers a diverse experience in playing with different game titles.
              </p> */}
              <div className='smart-div2'>
                <div class="caarrd">
                  <img src="\gv\STREET-SMART LOGO.png" alt="Uclash Logo" className="smart-img3" />
                </div>
                <div class="caarrd">
                  <img src="\gv\uclash logo (1).png" alt="Uclash Logo" className="smart-img3" />
                </div>
                <div class="caarrd">
                  <img src="\gv\pbp.png" alt="Uclash Logo" className="smart-img3" />
                </div>
                {/* <img src="\PBA\smart2.svg" alt="Uclash Logo" className="smart-img3" /> */}
              </div>
              <div className='smart-div3'>
                <div class="caarrd">
                  <img src="\gv\ubc logo.png" alt="Uclash Logo" className="smart-img6" />
                </div>
                <div class="caarrd">
                  <img src="\gv\Gamerfest Logo png.png" alt="Uclash Logo" className="smart-img3" />
                </div>
              </div>
              <div className='smart-div2'>
              
              </div>
          </div>
        </div>
    </div>
  )
}

export default Smart
