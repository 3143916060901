import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faFacebook, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './Font.css';

const App = () => {
  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
  };

  const columnStyle = {
    flex: 1,
  };

  const responsiveColumnStyle = {
    flex: '100%',
  };

  return (
    <footer style={{ backgroundColor: 'rgba(9, 9, 9, 0.9)', fontFamily: 'arial', marginTop:'-6%'}}>
    {/* <img
            src="\Img\Company_Logo.png"
            alt="Company Logo"
            style={{
              marginTop:'1%',
              width: '200px',
              height: 'auto',
              '@media (max-width: 600px)': {
                marginLeft: '-10vw', // Adjust for smaller screens
                width: '150px', // Adjust width for smaller screens
              },
            }}
        />    */}
    <div style={containerStyle}>
    <div style={columnStyle}>
        
        <p style={{color:'white', fontSize:'50px', fontWeight:'bolder', marginTop:'8%', marginLeft:'5%', fontFamily:'bai-bold'}}>
          ABOUT US
        </p> 
       
        <p style={{display:'flex' ,color:'white', textAlign:'justify', wordBreak:'break-all', marginLeft:'5%', marginRight:'5%', fontFamily:'bai-light'}}>
            Dark League Studios was founded in July 2022, and is the newest esports and gaming organizer in the industry.
            Our immediate goal is to be the top-of-mind grassroots events and esports organizer with the largest gaming community.
            Our vision is for the Philippines to be the Esports Tourism Destination in SEA by contributing to the sustainability of the industry, 
            and making esports culture something all generations across the country can appreciate.
        </p>
 
    </div>

      <div style={columnStyle}>
        <p style={{color:'white', fontSize:'50px', fontWeight:'bolder', marginTop:'8%', fontFamily:'bai-bold'}}>
            CONTACT US
        </p>
        <p style={{color:'white', textAlign:'justify', fontFamily:'bai-light'}}>
          <p style={{textAlign:'center', margin:'0%'}}>For partnerships and sponsorships,</p>
          <p style={{textAlign:'center', margin:'0%'}}>you may directly contact</p>
          <p style={{textAlign:'center', margin:'0%', fontWeight:'bolder'}}>events@darkleaguestudios.com</p>
        </p>
        <h1 style={{color:'white', fontSize:'20px', marginTop:'5%', fontFamily:'bai-reg'}}>
          LIKE AND FOLLOW
        </h1>
            <div className="social-icons" style={{marginRight:'10%'}}>
              <div className="social-icons" style={{ color: 'white', display: 'flex', textAlign: 'center', marginLeft: '10%' }}>
              <a href="https://www.tiktok.com/@darkleaguestudios?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTiktok}  style={{fontSize:'30px'}}/>
              </a>
              <a href="https://www.facebook.com/darkIeaguestudios" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} style={{fontSize:'30px'}}/>
              </a>
              <a href="https://www.instagram.com/darkleague_studios?utm_source=ig_web_button_share_sheet&igsh=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} style={{fontSize:'30px'}}/>
              </a>
              <a href="https://www.youtube.com/@darkleaguestudios" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} style={{fontSize:'30px'}}/>
              </a>
              <a href="https://www.linkedin.com/company/darkleaguestudios/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} style={{fontSize:'30px'}}/>
              </a>
              </div>
              </div>
      </div>
      <div style={columnStyle}>
        <p style={{color:'white', fontSize:'50px', fontWeight:'bolder', marginTop:'8%', marginLeft:'0%', fontFamily:'bai-bold'}}>
            LOCATION
        </p>
        <p style={{color:'white', textAlign:'justify', fontFamily:'bai-light'}}>
            <p style={{textAlign:'center', marginLeft:'0%', margin:'0%'}}>108 Central Building, E. Rodriguez Ave.,</p>
            <p style={{textAlign:'center', marginLeft:'0%', margin:'0%'}}>Bagumbayan, Quezon City, Philippines</p>
        </p>
      </div>
      <div style={{ ...columnStyle, ...responsiveColumnStyle, fontWeight:'bold', marginTop:'3%', marginBottom:'1%' }}>&copy; 2024 Dark League Studios</div>
    </div>

    </footer>
  );
};

export default App;
