import React, { useEffect, useRef } from 'react';
import { Container } from '@mui/material';
import './Who.css';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import VssionGoal from '../VissionGoal/VssionGoal';
import bg1 from '../bg/bg1.png';
import bg2 from '../bg/bg2.png';
import bg3 from '../bg/bg3.png';
import bg5 from '../bg/bg5.png';
import eevent44 from '../bg/eevent44.png';
import eevent11 from '../bg/eevent11.png';
import eevent22 from '../bg/eevent22.png';
import eevent33 from '../bg/eevent33.png';

function Who() {
  const whoRef = useRef(null);

  const images = [
    {
      label: 'Image 2',
      imgPath: eevent44,
    },
    {
      label: 'Image 2',
      imgPath: eevent11,
    },
    {
      label: 'Image 2',
      imgPath: eevent33,
    },
    {
      label: 'Image 2',
      imgPath: eevent22,
    },
    {
      label: 'Image 2',
      imgPath: bg1,
    },
    {
      label: 'Image 2',
      imgPath: bg2,
    },
    {
      label: 'Image 3',
      imgPath: bg3,
    },
    {
      label: 'Image 2',
      imgPath: bg5,
    },
    
  ];


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.5
      }
    );

    if (whoRef.current) {
      observer.observe(whoRef.current);
    }

    return () => {
      if (whoRef.current) {
        observer.unobserve(whoRef.current);
      }
    };
  }, []);

  return (
    <div id='who_we_are' className='who-div' ref={whoRef} >
      <Container>
        <div className='grid-containerc'>
          <div className='text-content'>
            <h1 className='who'>
              WHO
            </h1>
            <h2 className='we_are'>
                WE ARE
            </h2>
            <p className='des'>
            Dark League Studios, under RCV Digital Entertainment Group, is the fastest growing esports company today.  Registered with the Securities and Exchange Commission in July 2022, 
            DLS aims to unify esports stakeholders by bridging the gap between hardcore fans and casual gamers. Through highly unique Intellectual Properties, 
            grassroots events, industry partnerships, and fan inclusivity, we strive to become the Philippines’ leading esports organization, promising exciting developments ahead.
            </p>
          </div>
          <div className='image-content'>
            <div className="carousel-box">
              <Carousel
                useKeyboardArrows={true} 
                showArrows={true}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                speed= {5000} 
                autoplay= {true} 
                autoplaySpeed= {6000}>
                {images.map((image, index) => (
                  <div className="slide" key={index}>
                    <img alt={image.label} src={image.imgPath} />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </Container>
      <VssionGoal/>
    </div>
  );
}

export default Who;
