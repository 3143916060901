import React from 'react';
import { Typography } from '@mui/material';
import './Slide.css';

const slides = [
  {
    image: '/Img/News/SUMMEET.png',
    caption: 'Slide 1',
  },
  {
    image: '/Img/News/EENC.png',
    caption: 'Slide 2',
  },
  {
    image: '/Img/News/TMP.png',
    caption: 'Slide 3',
  },
  {
    image: '/Img/News/TEKKEN.png',
    caption: 'Slide 3',
  },
];

const App = () => {
  return (
    <div id='company_events'>
        <h1 className='env-up'>
          <span style={{fontFamily:'bai-light'}}>UPCOMING</span> EVENTS
      </h1>
    </div>
  );
};

export default App;