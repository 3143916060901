import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Counts from './Counts';

const App = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const bottom = window.innerHeight + window.scrollY;
      const element = document.querySelector('.counts-container');
      const top = element.offsetTop + element.offsetHeight;
      if (top < bottom) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check if element is visible on initial render

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="container" style={{ color:'black', textAlign:'center', fontFamily:'bai-reg', marginBottom:'10%', backgroundColor:'white', padding:'5%', marginTop:'-10%', paddingTop:'5%'
    }}>
      <Typography
        sx={{
          fontFamily: 'bai-bold',
          color: 'black',
          fontSize: { xs: '30px', md: '40px' },
          textAlign: 'center',
          margin: '5%',
          marginBottom:'2%'
        }}
      >
        BY THE NUMBERS
      </Typography>
      <div className={`counts-container ${isVisible ? 'visible' : ''}`} style={{paddingBottom:'5%'}}>
        <div className="count-item">
          <p style={{fontSize:'30px', marginBottom:'0%'}}>REACH</p>
          <Counts finalCount={600000000} duration={3700} fontSize={50}/>+
        </div>
        <div className="count-item">
          <p style={{fontSize:'30px', marginBottom:'0%'}}>VIEWS</p>
          <Counts finalCount={200000000} duration={3400} fontSize={45}/>+
        </div>
        <div className="count-item">
          <p style={{fontSize:'30px', marginBottom:'0%'}}>TOURNAMENTS</p>
          <Counts finalCount={500000} duration={3000} fontSize={45}/>+
        </div>
        <div className="count-item">
          <p style={{fontSize:'3   0px', marginBottom:'0%'}}>ESPORTS ACTIVATIONS</p>
          <Counts finalCount={300} duration={3700} fontSize={45}/>+
        </div>
      </div>
    </div>
  );
};

export default App;
