import React from 'react'
import './Partnership2.css';

function Partnership2() {
  return (
    <div>
      <div className="grid-coontainer">
        <h1 className='br2'>
          <span style={{fontFamily:'bai-light'}}>BRAND</span> PARTNERS
        </h1>
        <div className="grid-roow">
          <div className="grid-iteem"><img className='arena-parr' src='\Img\Icons\KFC Logo black.png' alt="PBA" /></div>
          <div className="grid-iteem"><img className='predator-parr' src='\Img\Icons\Copy of f68d5620_2021-msi-logo-for_digital_use_b.png' alt="PBA" /></div>
          <div className="grid-iteem"><img className='mwell-parr' src='\Img\Partners_Brands\mwell.png' alt="PBA" /></div>
          <div className="grid-iteem"><img className='con1-parr' src='\Img\Partners_Brands\arena-plus-logo.png' alt="PBA" /></div>
          <div className="grid-iteem"><img className='mwell1-parr' src='\Img\Assets\brands\pngegg (4).png' alt="PBA" /></div>
          <div className="grid-iteem"><img className='con-parr' src='\Img\Assets\brands\klipartz.com.png' alt="PBA" /></div>
        </div>
      </div>
    </div>
  )
}

export default Partnership2
