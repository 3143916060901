import React from 'react'
import  './VissionGoal.css';
import { Box } from '@mui/material';
import CompImg from '../VissionGoal/CompImg';

function VssionGoal() {
  return (
    <div id='goals_vision' className='div-vg'>
            {/* <CompImg/> */}
            <div>
              <h1 className='goal'>
              <span style={{fontFamily:'bai-light'}}>Our</span> Goal
              </h1>
              <img src="\gv\our goal.png" alt="Uclash Logo" className="res-imgs1" />
            </div> 
            <p className='goals'> 
              Our main goal is to be the top-of-mind grassroots events and esports organizer with the largest gaming community in the country.
            </p>
            <div className='vis-div'>
            <h2 className='vision'>
              <span style={{fontFamily:'bai-light'}}>Our </span>Vision
            </h2>
            
            
            <img src="\gv\our vision.png" alt="Uclash Logo" className="res-imgs2"/>
            <p className='visions'>
              Our vision is to make Philippines the leading Esports Tourism Destination in SEA, thereby, contributing 
              to the sustainability of the industry, and making esports culture something all generations across the country can appreciate.
            </p>
            </div>
    </div>
  )
}

export default VssionGoal
